input.transparent_input{
    background-color:rgba(0,0,0,0) !important;
    border:none !important;
}
.delete_button{
    position: absolute;
    right: 5px;
}
.relative{
    position: relative;
}